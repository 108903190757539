body {
  margin: 0;
  padding: 0;
  // overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    text-decoration: none;
    color: inherit;
  }
}

* {
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #848484;
  }
}

.hide-scrollbar {
  overflow: auto; /* Vẫn cho phép cuộn nếu cần */
  scrollbar-width: none; /* Dành cho Firefox */
  -ms-overflow-style: none; /* Dành cho Internet Explorer và Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Dành cho Chrome, Safari và Opera */
}

.ff-title {
  font-family: "Public Sans", sans-serif;
  color: #ab0033;
}
.min-h-100 {
  min-height: 100vh;
}

.max-h-100 {
  max-height: 100vh !important;
}

.-mt-1 {
  margin-top: -8px;
}

.-mt-3 {
  margin-top: -18px;
}

.-mb-1 {
  margin-bottom: -8px;
}
.-ms-1 {
  margin-left: -8px;
}
.-me-1 {
  margin-right: -8px;
}

.-m-1 {
  margin: -8px;
}

.min-w-300px {
  min-width: 300px;
}

.min-w-150px {
  min-width: 150px !important;
}

.min-w-100px {
  min-width: 100px;
}

.h-100px {
  height: 100px;
}
.h-60px {
  height: 60px;
}

.h-0 {
  height: 0;
}

.w-0 {
  width: 0;
}

.text-12 {
  font-size: 12px !important;
}

.text-14 {
  font-size: 14px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-scrollbar {
  overflow: auto;
  max-height: 75vh;
}

.positionX-center {
  left: 50%;
  transform: translateX(-50%);
}

.position-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bottom-12px {
  bottom: 12px;
}

button {
  &:disabled {
    opacity: 0.4 !important;
  }
}

span.required {
  color: red;
}
#search-date .react-datepicker-wrapper {
  display: block;
}

.image-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  &.ratio-169 {
    padding-top: calc(100% * 9 / 16);
    border-radius: 12px;
  }
  &.ratio-43 {
    padding-top: calc(100% * 3 / 4);
    border-radius: 12px;
  }
}
#image_lazyload {
  object-fit: cover;
  object-position: center;
  background-repeat: no-repeat;
}
.custom-picker {
  --bs-tooltip-max-width: unset !important;
}
.link_router {
  &:hover {
    color: #0d6efd;
  }
}
.result-search {
  max-height: calc(100vh - 300px) !important;
}
.result-table {
  max-height: calc(100vh - 150px) !important;
}
@media only screen and (max-width: 992px) {
  .hidden-widget {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .hideMobile {
    display: none;
  }
}
