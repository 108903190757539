#popup-image {
  border-radius: 5px;
  transition: 0.3s;
  z-index: 1056; // layout high

  position: fixed;
  top: 0;
  height: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    position: fixed;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
  }

  .popup-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }

  .popup-content {
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@media only screen and (max-width: 700px) {
  #popup-image .popup-content {
    width: 100%;
  }
}
