.header {
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 0.5px solid rgba(145, 158, 171, 0.3);
}

.account-header {
  cursor: pointer;
  position: relative;
  .avatar-account {
    border: 2px solid rgba(145, 158, 171, 01);
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }
  .account-info {
    .user-name {
      font-size: 15px;
    }
    .user-role {
      font-size: 13px;
      color: #2a2a2a;
    }
  }
  .sub-menu-account {
    width: 220px;
    border-radius: 5px;
    position: absolute;
    z-index: 999;
    top: 50px;
    right: 0px;
    background-color: #fff;
    border: 1px solid rgba(145, 158, 171, 0.2);
    li {
      padding: 10px 0 10px 20px;
      &.last_item {
        border-top: 1px solid rgba(145, 158, 171, 0.8);
      }
      &:hover {
        background-color: #ddd;
        & a {
          color: #f89c2e;
        }
      }
    }
  }
}

#navbar {
  .nav-item {
    .nav-link {
      border-radius: 12px 0 12px 0;
      color: #ab0033;
      border: 2px solid #ab0033;
      &.active,
      &:hover {
        background-color: #ab0033;
        color: #ffe300;
      }
    }
  }
}
.dropdown-menu {
  .dropdown-item {
    &:focus {
      background-color: #6c757d;
      color: #fff;
    }
    &:hover {
      background-color: #6c757d;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 992px) {
  .account-header {
    .sub-menu-account {
      top: -50px;
      left: 90px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .brand-header,
  .account-info {
    display: none;
  }
  .avatar-account {
    border: 2px solid rgba(145, 158, 171, 01);
    border-radius: 50%;
    width: 45px !important;
    height: 45px !important;
  }
}
