.social-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
}

.pulse-button {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Thêm box-shadow */
  animation: pulse 1.5s infinite;
  transition: box-shadow 0.3s ease; /* Hiệu ứng transition cho box-shadow */
}

.pulse-button:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5); /* Tăng độ mạnh khi hover */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
