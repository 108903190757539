.custom-progress-bar {
  width: 100%;
  height: 6px;
  position: relative;
}

.indeterminate-bar {
  // background-color: #007bff; /* Màu sắc thanh tiến trình */
  background: linear-gradient(
    to right,
    rgb(255, 0, 0),
    rgb(0, 255, 0),
    rgb(0, 0, 255)
  );
  width: 100%;
  height: 100%;
  position: absolute;
  animation: indeterminate 2s linear infinite;
}

@keyframes indeterminate {
  0% {
    left: -50%;
    right: 100%;
  }
  50% {
    left: 100%;
    right: -50%;
  }
  100% {
    left: 100%;
    right: -50%;
  }
}
